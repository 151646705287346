<template>
  <v-card to="/events">
    <v-card-title>{{$t('t.Events')}}</v-card-title>
    <apexchart
      width="660px"
      height="200px"
      :options="chartOptions"
      :series="series"
    />
  </v-card>
</template>

<script>
import Apexchart from 'vue-apexcharts'

export default {
  components: {
    Apexchart
  },
  data () {
    return {
      chartSeries: []
    }
  },
  methods: {
    goToEvent: function (event, chartContext, config) {
      this.$router.push('/events')
    }
  },
  mounted: function () {
  },
  computed: {
    chartOptions () {
      return {
        chart: {
          type: 'bar',
          background: 'none',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        annotations: {
          yaxis: [{
            y: 0,
            borderColor: '#00E396',
            label: {
              borderColor: '#00E396',
              style: {
                color: '#fff',
                background: '#00E396'
              }
            }
          }],
          xaxis: [{
            x: new Date('14 Feb 2020').getTime(),
            x2: new Date('29 Feb 2020').getTime(),
            fillColor: '#B3F7CA',
            opacity: 0.1,
            borderColor: '#775DD0',
            label: {
              borderColor: '#775DD0',
              style: {
                color: '#fff',
                background: '#775DD0'
              },
              offsetX: 20,
              text: 'prediction'
            }
          }]
        },
        tooltip: {
          shared: false,
          followCursor: false,
          enabled: true
        },
        markers: {
          size: 7,
          colors: '#FFFF00',
          strokeColors: '#ffff00'
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [{
                from: 0,
                to: 1000,
                color: '#23d135'
              },
              {
                from: 0,
                to: 0,
                color: '#000000'
              },
              {
                from: -1000,
                to: 0,
                color: '#f22b2b'
              }]
            },
            columnWidth: '80%'
          }
        },
        xaxis: {
          type: 'datetime',
          categories: [
            '2020-01-01',
            '2020-01-05',
            '2020-01-11',
            '2020-01-15',
            '2020-01-21',
            '2020-01-25',
            '2020-01-30',
            '2020-02-05',
            '2020-02-10',
            '2020-02-12',
            '2020-02-13',
            '2020-02-28'
          ],
          labels: {
            rotate: -90
          }
        },
        theme: {
          mode: this.$vuetify.theme.dark ? 'dark' : 'light',
          palette: 'palette1'
        }
      }
    },
    series () {
      return [{
        name: 'received',
        type: 'bar',
        data: [4, -3, 8, -6, 2, 0, 1, -8, -9, -12, -9]
      },
      {
        name: 'prediction',
        type: 'bar',
        data: [null, null, null, null, null, null, null, null, null, null, null, -3]
      }]
    }
  }
}
</script>

<style lang="stylus" scoped>
/deep/.apexcharts-canvas.apexcharts-theme-dark
  background none
</style>
